body{
  background-color: aqua;
}


.box{
  background-color: black;
  padding: 4vh;
color: orange;
margin-bottom: 20vh;

  
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
margin: auto;
margin-top: 4vh;
flex-direction: column;

  height:120vh;
  width: 20vw;

}